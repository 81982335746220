import { createRouter, createWebHistory } from 'vue-router'
import CroppingCalendar from '../views/CroppingCalendar.vue'
// import CroppingCalendarNew from '../views/CroppingCalendarNewTwo.vue'
import CroppingCalendarNew from '../views/CroppingCalendarUpdating.vue'
import Home from '../views/Home.vue'
import Weather from '../views/weatherHome.vue'
import Main from '../views/Main.vue'
import StorySet from '../views/storyset.vue'
import DataSets from '../views/datasets.vue'
import services from '../views/tools.vue'
import DatasetPreview from '../views/datasetpreview.vue'
import Storysetchartboard from '../views/storysetchartboard'
import tempRain from '../views/temprain'
import ControlCenter from '../views/controlCenter'
import resources from '../views/resources'
import resources_cms from '../views/resources_cms'
import faq from '../views/faq.vue'
import stepper from '../views/form.vue'
import bundles from '../views/bundles.vue'
import Leaflet from '../views/leaflet.vue'
import MapDetails from '../views/mapdetails.vue'
import DistrictMap from '../views/districtmap.vue'
import farm_cord from '../views/farmCord.vue'
import CalendarMore from '../views/calenderMore.vue'
import Reset from '../views/passwordReset.vue'




import weatherBg from "@/assets/weatherbg1.jpg"





const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/password_reset/:token',
    name: 'Reset',
    component: Reset
  },
  {
    path: '/weather',
    name: 'weather',
    component: Weather,
    meta: {  bgImage: weatherBg }
  },
  {
    path: "/controlcenter",
    name: 'ControlCenter',
    component: ControlCenter
    
  },
  {
    path: "/technologies",
    beforeEnter(to, from, next) {
      window.location.href = "https://technologies.csirgh.com/";
    }
  },
  {
    path: "/learn",
    beforeEnter(to, from, next) {
      window.location.href = "https://learn.csirgh.com/";
    }
  },
  {
    path: "/kuafo",
    beforeEnter(to, from, next) {
      window.location.href = "http://kuafo.csirgh.com/";
    }
  },
  {
    path: "/advisor",
    beforeEnter(to, from, next) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.insti.kuafo_Advisor";
    }
  },
  {
    path: "/csirspace",
    beforeEnter(to, from, next) {
      window.location.href = "http://csirspace.csirgh.com/";
    }
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    children: [
      {
        path: 'Croppingcalendarold',
        name: 'CroppingCalendarold',
        component: CroppingCalendar
      },
      {
        path: 'croppingcalendar',
        name: 'CroppingCalendar',
        component: CroppingCalendarNew,
      },
      {
        path:'more',
        name:'More',
        component:CalendarMore
      },
      {
        path: 'leaflet',
        name: 'Leaflet',
        component: Leaflet
      },
      {
        path: 'district_map',
        name: 'DistrictMap',
        component: DistrictMap
      },
      {
        path: 'farm_cord',
        name: 'FarmCord',
        component: farm_cord
      },
      {
        path: 'mapdetails/:id',
        name: 'MapDetails',
        component: MapDetails
      },
      {
        path: 'temprain',
        name: 'TempRain',
        component: tempRain
      },
      {
        path: 'storyset',
        name: 'Storyset',
        component: StorySet
      },
      {
        path: 'storysetchartboard/:data',
        name: 'Storysetchartboard',
        component: Storysetchartboard,
        props: (route) => ({
          data: route.params.data,
          ...route.params
        })
      },
      {
        path: 'services',
        name: 'services',
        component: services
      },
      {
        path: "resources",
        name: 'resources',
        component: resources
      },
      {
        path: "faq",
        name: 'faq',
        component: faq
      },
      {
        path: "stepper",
        name: 'stepper',
        component: stepper
      },
      {
        path: "bundles",
        name: 'bundles',
        component: bundles
      },
      {
        path: "resources_cms",
        name: 'resources_cms',
        component: resources_cms
      },
      {
        path: 'datasets',
        name: 'Datasets',
        component: DataSets,
      },
      {
        path: 'datasetpreview',
        name: 'DatasetPreview',
        component: DatasetPreview
      },
      {
        path: 'dashboard',
        name: 'Dashbord',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
      }
    ]
  },
  
]



const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
  